<template>
  <div id="app">
    <h1 class="langCng" lang="ja" style="display:none">【スズキ㈱】システムご利用者登録のお願い</h1>
    <h1 class="langCng" lang="en" >[ST][SUZUKI MOTOR CORPORATION] The check request of your e-mail address for using SSO system</h1>
    <h1 class="langCng" lang="cs" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Žádost o kontrolu vaší e-mailové adresy pro používání systému SSO</h1>
    <h1 class="langCng" lang="da" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Kontrol af email. Vi tester om den angivne email er korrekt inden SSO-systemet kan benyttes</h1>
    <h1 class="langCng" lang="nl" style="display:none">[ST][SUZUKI MOTOR CORPORATION] E-mail validatie noodzakelijk voor gebruik SSO systeem</h1>
    <h1 class="langCng" lang="es" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Solicitud de verificación de su dirección de email para utilizar el sistema SSO</h1>
    <h1 class="langCng" lang="fr" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Demande de vérification de votre e-mail pour l’utilisation du système SSO</h1>
    <h1 class="langCng" lang="de" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Prüfanweisung für Ihre E-Mailadresse zur Verwendung des SSO-Systems</h1>
    <h1 class="langCng" lang="el" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Το αίτημα ελέγχου της διεύθυνσης ηλεκτρονικού ταχυδρομείου σας για χρήση του συστήματος SSO</h1>
    <h1 class="langCng" lang="hu" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Felkérés e-mail cím ellenőrzésére az SSO rendszer használatához</h1>
    <h1 class="langCng" lang="it" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Richiesta di verifica del tuo indirizzo e-mail per l'utilizzo del sistema SSO</h1>
    <h1 class="langCng" lang="no" style="display:none">[ST][SUZUKI MOTOR CORPORATION] E-postbekreftelse for bruk av SSO system</h1>
    <h1 class="langCng" lang="pl" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Prośba o sprawdzenie Twojego adresu e-mail w celu korzystania z systemu SSO</h1>
    <h1 class="langCng" lang="pt" style="display:none">[ST][SUZUKI MOTOR CORPORATION] O pedido de verificação do seu endereço de e-mail para utilização do sistema SSO</h1>
    <h1 class="langCng" lang="ro" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Solicitarea de verificare a adresei dvs. de e-mail pentru utilizarea sistemului SSO</h1>
    <h1 class="langCng" lang="sk" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Požiadavka na kontrolu vášho e-mailu na používanie systému SSO</h1>
    <h1 class="langCng" lang="sv" style="display:none">[ST][SUZUKI MOTOR CORPORATION] Begäran om att kontrollera din e-postadress för att använda SSO-system</h1>
    <h1 class="langCng" lang="he" style="display:none">[ST][SUZUKI MOTOR CORPORATION] בקשת הבדיקה של כתובת הדואר האלקטרוני שלך לשימוש במערכת SSO TEST</h1>
    <table align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none">
      スズキ㈱のシステムご利用に当たりまして、以下に記載の内容をご確認の上、<br />
      同意いただけましたら、「はい」をクリックしていただきますようお願い申し上げます。<br />

      尚、同意いただけない場合は、スズキ㈱のシステムをご利用いただく<br />
      ことができませんのでご了承下さい。<br /><br />

      </p>
      <p class="langCng" lang="en" >
        The application to the system has been accepted for the user's registration or the Email-address change.<br />
      When the applied Email-address is invalid, you cannot receive message from us.<br />
      Therefore, please operate as follows whether the Email-address is correct for our check.<br /><br />
      When the address of the sent Email is correct, please click 'Yes' button.<br />
      When it is wrong or there is no remembrance in the content of Email, please click 'No' button.<br /><br />
      </p>
      <p class="langCng" lang="cs" style="display:none">
        V systému byla přijata žádost o registraci uživatele nebo změnu e-mailové adresy.<br />
      Pokud je použitá e-mailová adresa neplatná, nemůžete přijímat naše zprávy.<br />
      Postupujte proto následovně, abychom ověřili, zda je e-mailová adresa správná.<br /><br />
      Pokud je adresa zaslaného e-mailu správná, klikněte na tlačítko „Ano“.<br />
      Pokud je chybná, nebo obsah e-mailu není srozumitelný, klikněte na tlačítko „Ne“.<br /><br />
      </p>
      <p class="langCng" lang="da" style="display:none">
        Ansøgning om brug af systemet efter brugerens registrering af eller ændring af e-mailadressen er accepteret.<br />
      Hvis den oplyste e-mailadresse ikke eksiterer, vil du ikke kunne modtage mails fra systemet. <br />
      Kontroller derfor om den angivne emailadresse er korrekt.<br /><br />
      Er den korrekt trykkes der på 'Ja'. <br />
      Er den angivne mailadresse ikke korrekt trykkes der på 'Nej'.<br /><br />
      </p>
      <p class="langCng" lang="nl" style="display:none">
        De aanvraag voor registratie of wijziging e-mailadres is geaccepteerd.<br />
      Wanneer het opgevoerde e-mailadres incorrect is zult u geen berichten van ons kunnen ontvangen.<br />
      Om die reden vragen we u het e-mailadres goed te controleren. <br /><br />
      Indien het e-mailadres correct is, bevestig via de knop 'Ja'. <br />
      Blijkt het incorrect, kies van voor de knop 'Nee'.<br /><br />
      </p>
      <p class="langCng" lang="es" style="display:none">
        La solicitud al sistema ha sido aceptada para el registro del usuario o el cambio de dirección de email.<br />
      Si la dirección de email solicitada no es válida, no podrá recibir nuestros mensajes.<br />
      Por lo tanto, por favor, opere como sigue si la dirección de email es correcta para nuestra comprobación.<br /><br />
      Si la dirección del email enviado es correcta, haga clic en el botón "Sí".<br />
      Si es incorrecto o no hay registro en el contenido del email, haga clic en el botón "No"".<br /><br />
      </p>
      <p class="langCng" lang="fr" style="display:none">
        La demande d’enregistrement d’utilisateur ou de modification d’e-mail transmise au système a été acceptée.<br />
      Si l’e-mail indiqué n’est pas correct, vous ne recevrez pas nos messages.<br />
      Aussi, veuillez procéder comme suit afin de nous confirmer votre e-mail.<br /><br />
      Si l’e-mail auquel nous vous avons envoyé un message est correct, veuillez cliquer sur le bouton « Oui ».<br />
      En cas d’erreur ou si vous ne vous souvenez pas du contenu de l’e-mail, veuillez cliquer sur « Non ».<br /><br />
      </p>
      <p class="langCng" lang="de" style="display:none">
        Der Antrag zur Registrierung oder Änderung der E-Mailadresse wurde angenommen.<br />
      Falls die angegebene E-Mailadresse falsch sein sollte, können Sie keine Nachrichten von uns empfangen.<br />
      Prüfen Sie daher ob die E-Mailadresse korrekt angegeben wurde.<br /><br />
      Wenn die E-Mailadresse korrekt ist, klicken Sie auf Ja.<br />
      Wenn Sie falsch ist, klicken Sie auf Nein.<br /><br />
      </p>
      <p class="langCng" lang="el" style="display:none">
        Η αίτηση στο σύστημα έχει γίνει αποδεκτή για την εγγραφή του χρήστη ή την αλλαγή διεύθυνσης email.<br />
      Όταν η διεύθυνση email της αίτησης δεν είναι έγκυρη, δεν μπορείτε να λάβετε μήνυμα από εμάς.<br />
      Επομένως, λειτουργήστε ως εξής εάν η διεύθυνση email είναι σωστή για τον έλεγχο μας.<br /><br />
      Όταν η διεύθυνση του σταλμένου email είναι σωστή, κάντε κλικ στο μπουτόν «Ναι».<br />
      Όταν είναι λάθος ή δεν υπάρχει ανάμνηση στο περιεχόμενο του email, κάντε κλικ στο μπουτόν «Όχι».<br /><br />
      </p>
      <p class="langCng" lang="hu" style="display:none">
        A felhasználó regisztrálásával vagy az e-mail cím módosításával kapcsolatban a rendszerbe beküldött kérelem elfogadásra került.<br />
      Ha a rögzített e-mail cím nem érvényes, üzeneteink nem jutnak el Önhöz.<br />
      Ezért kérjük, az alábbiakban erősítse meg, hogy az e-mail címe helyes-e.<br /><br />
      Ha a kiküldött e-mailben szereplő cím helyes, kérjük, kattintson az „Igen” gombra.<br />
      Ha helytelen, vagy ha nem ismerős Önnek az e-mailben említett tevékenység, kattintson a „Nem” gombra.<br /><br />
      </p>
      <p class="langCng" lang="it" style="display:none">
        La richiesta di accesso al sistema è stata accettata per la registrazione dell'utente o per la modifica dell'indirizzo e-mail.<br />
      Quando l'indirizzo e-mail indicato non è valido, non puoi ricevere messaggi da noi.<br />
      Pertanto, per nostro controllo agire come segue se l'indirizzo e-mail è corretto.<br /><br />
      Se l'indirizzo dell'e-mail inviata è corretto, cliccare sul pulsante 'Sì'.<br />
      Se è sbagliato o se il contenuto dell'e-mail vi è ignoto, cliccare sul pulsante "No".<br /><br />
      </p>
      <p class="langCng" lang="no" style="display:none">
        Forespørsel om endring av registrert bruker eller e-postadresse er godkjent.<br />
      Du vil ikke kunne motta meldinger fra oss om den brukte e-postadressen blir gjort ugyldig.<br />
      Gjør som følger for å bekrefte e-postadresse.<br /><br />
      Om e-postadressen er korrekt, klikk på 'Ja'.<br />
      Om e-postadressen er feil eller innholdet i e-posten er ukjent, klikk på 'Nei'.<br /><br />
      </p>
      <p class="langCng" lang="pl" style="display:none">
        Zgłoszenie do systemu zostało przyjęte na potrzeby rejestracji użytkownika lub zmiany adresu e-mail.<br />
      Jeśli podany adres e-mail jest nieprawidłowy, nie możesz otrzymywać od nas wiadomości.<br />
      Dlatego proszę postępować w następujący sposób, czy adres e-mail jest prawidłowy?.<br /><br />
      Gdy adres wysłanego e-maila jest poprawny, kliknij przycisk „Tak”.<br />
      Jeśli jest niepoprawny, kliknij przycisk „Nie”.<br /><br />
      </p>
      <p class="langCng" lang="pt" style="display:none">
        O pedido ao sistema foi aceite para o registo do utilizador ou para a alteração do endereço de e-mail.<br />
      Quando o endereço de e-mail aplicado é inválido, não pode receber mensagens nossas.<br />
      Por conseguinte, por favor, funcione como se segue se o endereço de e-mail está correcto para a nossa verificação.<br /><br />
      Quando o endereço do e-mail enviado estiver correcto, por favor clique no botão 'Sim'.<br />
      Quando estiver errado ou não houver lembrança no conteúdo do e-mail, por favor clique no botão 'Não'".<br /><br />
      </p>
      <p class="langCng" lang="ro" style="display:none">
        Solicitarea către sistem a fost acceptată pentru înregistrarea utilizatorului sau pentru schimbarea adresei de e-mail.<br />
      Dacă adresa de e-mail indicată nu este valabilă, nu puteți primi mesaj de la noi.<br />
      Vă rugăm așadar, dacă adresa de e-mail este corectă, să procedați după cum urmează, pentru verificarea noastră.<br /><br />
      Dacă adresa e-mailului trimis este corectă, vă rugăm să faceți clic pe butonul „Da”.<br />
      Dacă este greșită sau nu există nicio referire în conținutul e-mailului, faceți clic pe butonul „Nu”.<br /><br />
      </p>
      <p class="langCng" lang="sk" style="display:none">
        Žiadosť o registráciu používateľa alebo zmenu e-mailovej adresy v systéme bola akceptovaná.<br />
      Ak je použitá e-mailová adresa neplatná, nebudete od nás dostávať správy.<br />
      Na overenie e-mailovej adresy použite nasledujúci postup.<br /><br />
      Ak je adresa odoslaného e-mailu správna, kliknite na tlačidlo „Áno“.<br />
      Ak je nesprávna alebo neuvedená v obsahu e-mailu, kliknite na tlačidlo „Nie“.<br /><br />
      </p>
      <p class="langCng" lang="sv" style="display:none">
        Ansökan till systemet om att registrera användaren eller ändra e-postadress har godkänts.<br />
      Om en ogiltig e-postadress har registrerats kan du inte få meddelanden från oss.<br />
      Gör därför enligt följande beroende på om e-postadressen är korrekt i vår kontroll:<br /><br />
      Om adressen som e-postmeddelandet har skickats till är korrekt klickar du på knappen Ja.<br />
      Om den är fel eller du inte känner till innehållet i e-postmeddelandet klickar du på knappen Nej.<br /><br />
      </p>
      <p class="langCng" lang="he" style="display:none">
        הבקשה למערכת התקבלה לרישום המשתמש או לשינוי כתובת האימייל.<br />
      כאשר כתובת האימייל שהוחלה אינה חוקית, אינך יכול לקבל מאיתנו הודעה.<br />
      לכן, אנא פעל באופן הבא אם כתובת האימייל נכונה לבדיקה שלנו.<br /><br />
      כאשר כתובת האימייל שנשלחה נכונה, אנא לחץ על כפתור 'כן'.<br />
      כאשר זה שגוי או שאין זיכרון בתוכן של דוא"ל, אנא לחץ על כפתור 'לא'.<br /><br />
      </p>
        </td>
      </tr>
    </table>
    <button class="langCng" lang="ja" style="display:none" v-on:click="postYes">はい</button>
    <button class="langCng" lang="ja" style="display:none" v-on:click="postNo">いいえ</button>
    <button class="langCng" lang="en"  v-on:click="postYes">Yes</button>
    <button class="langCng" lang="en"  v-on:click="postNo">No</button>
    <button class="langCng" lang="cs" style="display:none" v-on:click="postYes">Ano</button>
    <button class="langCng" lang="cs" style="display:none" v-on:click="postNo">Ne</button>
    <button class="langCng" lang="da" style="display:none" v-on:click="postYes">Ja</button>
    <button class="langCng" lang="da" style="display:none" v-on:click="postNo">Nej</button>
    <button class="langCng" lang="nl" style="display:none" v-on:click="postYes">Ja</button>
    <button class="langCng" lang="nl" style="display:none" v-on:click="postNo">Nee</button>
    <button class="langCng" lang="es" style="display:none" v-on:click="postYes">Sí</button>
    <button class="langCng" lang="es" style="display:none" v-on:click="postNo">No</button>
    <button class="langCng" lang="fr" style="display:none" v-on:click="postYes">Oui</button>
    <button class="langCng" lang="fr" style="display:none" v-on:click="postNo">Non</button>
    <button class="langCng" lang="de" style="display:none" v-on:click="postYes">Ja</button>
    <button class="langCng" lang="de" style="display:none" v-on:click="postNo">Nein</button>
    <button class="langCng" lang="el" style="display:none" v-on:click="postYes">Ναι</button>
    <button class="langCng" lang="el" style="display:none" v-on:click="postNo">Όχι</button>
    <button class="langCng" lang="hu" style="display:none" v-on:click="postYes">Igen</button>
    <button class="langCng" lang="hu" style="display:none" v-on:click="postNo">Nem</button>
    <button class="langCng" lang="it" style="display:none" v-on:click="postYes">Sì</button>
    <button class="langCng" lang="it" style="display:none" v-on:click="postNo">No</button>
    <button class="langCng" lang="no" style="display:none" v-on:click="postYes">Ja</button>
    <button class="langCng" lang="no" style="display:none" v-on:click="postNo">Nei</button>
    <button class="langCng" lang="pl" style="display:none" v-on:click="postYes">Tak</button>
    <button class="langCng" lang="pl" style="display:none" v-on:click="postNo">Nie</button>
    <button class="langCng" lang="pt" style="display:none" v-on:click="postYes">Sim</button>
    <button class="langCng" lang="pt" style="display:none" v-on:click="postNo">Não</button>
    <button class="langCng" lang="ro" style="display:none" v-on:click="postYes">Da</button>
    <button class="langCng" lang="ro" style="display:none" v-on:click="postNo">Nu</button>
    <button class="langCng" lang="sk" style="display:none" v-on:click="postYes">Áno</button>
    <button class="langCng" lang="sk" style="display:none" v-on:click="postNo">Nie</button>
    <button class="langCng" lang="sv" style="display:none" v-on:click="postYes">Ja</button>
    <button class="langCng" lang="sv" style="display:none" v-on:click="postNo">Nej</button>
    <button class="langCng" lang="he" style="display:none" v-on:click="postYes">כן</button>
    <button class="langCng" lang="he" style="display:none" v-on:click="postNo">לא</button>
    <br /><br />
    <table id="notice" align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none">
            ご登録いただきました個人情報は、スズキ㈱個人情報基本方針、スズキプライバシーノーティス 及び/または スズキプライバシーポリシーに則り、
            郵便・電話・電子メールの方法によりシステムの管理運用上の案内を提供する目的に限り使用します。<br /><br />
            i)スズキ㈱個人情報基本方針（日本のお取引先様用）(日本語):<br />
            <a href="https://www.suzuki.co.jp/privacy_statement/" target="_blank" rel="noopener noreferrer">https://www.suzuki.co.jp/privacy_statement/</a><br />
            ii)スズキプライバシーノーティス（EEA域内のお取引先様用）(英語):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            iii)スズキプライバシーポリシー（その他地域のお取引先様用）(英語等):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
          </p>
          <p class="langCng" lang="en" >
            The personal information entered herein will be used exclusively for the purpose of providing information regarding management and operation of the system by mail,
            telephone and e-mail, and in accordance with Privacy Notice for processing personal data and/or Privacy Policies below.<br /><br />
            Privacy Notice for processing personal data (For users in the EEA) (in English):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Our Privacy Policies (For users outside the EEA) (in English, etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*In EEA, the personal information may be provided to Magyar Suzuki Corporation where services may be provided by it.<br />
            (e.g. Suzuki Connected System)<br />
            The Magyar Suzuki Corporation Privacy Notice is here.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="cs" style="display:none">
            Zde zadané osobní údaje budou použity výhradně k poskytování informací týkajících se správy a operací systému poštou,
            telefonicky a e-mailem, a to v souladu se ochranou osobních údajů pro zpracování osobních údajů a/nebo našimi zásadami ochrany osobních údajů uvedenými níže.<br /><br />
            Oznámení o ochraně osobních údajů pro zpracování osobních údajů (Pro uživatele v Evropského hospodářského prostoru) (v angličtině):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Naše zásady ochrany osobních údajů společnosti Suzuki Motor Corporation (Pro uživatele mimo Evropského hospodářského prostoru) (anglicky atd.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*V Evropském hospodářském prostoru mohou být osobní údaje poskytovány společnosti Magyar Suzuki Corporation a mohou být poskytovány služby. <br />
            (např. Suzuki Connected System)<br />
            Ochranu osobních údajů společnosti Magyar Suzuki Corporation najdete zde.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br />-->
          </p>
          <p class="langCng" lang="da" style="display:none">
            De personlige oplysninger, der indtastes her, vil udelukkende blive brugt med det formål at give oplysninger vedrørende styring og drift af systemet via mail,
            telefon og e-mail, og i overensstemmelse med Bekendtgørelsen om beskyttelse af personlige oplysninger for behandling af personlige data og/eller Privatlivspolitikker nedenfor.<br /><br />
            Bekendtgørelse om beskyttelse af personlige oplysninger for behandling af personoplysninger (For brugere i EØS og på engelsk):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Privatlivspolitik for brugere uden for EØS på engelsk osv.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*I EØS (Det Europæiske Økonomiske Samarbdejsområde) kan de personlige oplysninger leveres til Magyar Suzuki Corporation, og tjenester herfra kan leveres.<br />
            (f.eks. Suzuki Connected System)<br />
            Magyar Suzuki Corporations fortrolighedserklæring er her.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br />-->
          </p>
          <p class="langCng" lang="nl" style="display:none">
            De persoonsgegevens die opgeslagen worden zullen enkel gebruikt worden om u te informeren over het beheer en gebruik van het systeem.
            Dit kan via post, e-mail of telefoon. Gegevensverwerking is gebaseerd op het privacy statement voor het verwerken van persoonsgegevens en/of ondervermelde privacy beleid van.<br /><br />
            Privacy statement voor verwerken persoonsgegevens (voor gebruikers in de EEA, in Engels):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Privacy beleid Suzuki Motor Corporation (voor gebruikers buiten de EEA, in Engels etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--* Binnen EEA kunnen persoonsgegevens verstrekt worden aan Magyar Suzuki Corporation. <br />
            (bijv. Suzuki Connected System)<br />
            Hier vind u het betreffende privacy statement:<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="es" style="display:none">
            La información personal introducida se utilizará exclusivamente con el fin de proporcionar información sobre la gestión y el funcionamiento del sistema por correo,
            teléfono y email, y de acuerdo con el Aviso de Privacidad para el procesamiento de datos personales y/o Nuestras Políticas de Privacidad a continuación.<br /><br />
            Política de privacidad para el procesamiento de datos personales (para usuarios en el EEE) (en inglés):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Nuestras políticas de privacidad (para usuarios fuera del EEE) (en inglés, etc.)<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*En el EEE, la información personal puede ser proporcionada a Magyar Suzuki Corporation y los servicios pueden ser proporcionados.<br />
            (por ejemplo, Suzuki Connected System)<br />
            El aviso de privacidad de Magyar Suzuki Corporation está aquí.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="fr" style="display:none">
            Les informations personnelles fournies ici seront utilisées exclusivement afin de vous fournir des informations concernant la gestion et le fonctionnement du système par courrier,
            téléphone ou e-mail, et ce, conformément à la déclaration de confidentialité relative au traitement des données personnelles et/ou la politique de confidentialité disponibles ci-dessous.<br /><br />
            Privacy Notice for processing personal data (For users in the EEA) (in English):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Déclaration de confidentialité relative au traitement des données personnelles (pour les utilisateurs de l’EEE) (en anglais):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*Au sein de l’EEE, les informations personnelles peuvent être fournies à Magyar Suzuki Corporation et des services peuvent être fournis par elle.<br />
            (p. ex. Suzuki Connected System)<br />
            El aviso de privacidad de Magyar Suzuki Corporation está aquí.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br />-->
          </p>
          <p class="langCng" lang="de" style="display:none">
            Die hier eingegebenen personenbezogenen Daten werden ausschließlich zum Zwecke der Bereitstellung von Informationen über die Verwaltung und den Betrieb des Systems per Post,
            Telefon und E-Mail sowie in Übereinstimmung mit der Datenschutzerklärung für die Verarbeitung personenbezogener Daten und/oder den nachstehenden Datenschutzrichtlinien verwendet.<br /><br />
            Datenschutzerklärung für die Verarbeitung personenbezogener Daten (Für Nutzer im EWR) (in englischer Sprache):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Unsere Datenschutzrichtlinien (Für Benutzer außerhalb des EWR) (in englischer Sprache usw.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*Im EWR können die personenbezogenen Daten der Magyar Suzuki Corporation zur Verfügung gestellt werden, wo Dienstleistungen von ihr erbracht werden können.<br />
            (z.B. Suzuki Connected System)<br />
            Die Datenschutzerklärung der Magyar Suzuki Corporation finden Sie hier.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br />-->
          </p>
          <p class="langCng" lang="el" style="display:none">
            Οι προσωπικές πληροφορίες που εισάγονται στο παρόν θα χρησιμοποιηθούν αποκλειστικά για την παροχή πληροφοριών σχετικά με τη διαχείριση και τη λειτουργία του συστήματος μέσω ταχυδρομείου,
            τηλεφώνου και ηλεκτρονικού ταχυδρομείου και σύμφωνα με τη Δήλωση Απορρήτου για την επεξεργασία προσωπικών δεδομένων ή/και πολιτικών απορρήτου παρακάτω.<br /><br />
            Δήλωση προστασίας προσωπικών δεδομένων για την επεξεργασία προσωπικών δεδομένων (για χρήστες στον ΕΟΧ) (στα αγγλικά):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Οι Πολιτικές Απορρήτου μας (για άλλους προμηθευτές) (στα Αγγλικά, κ.λπ.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*Στον ΕΟΧ, τα προσωπικά στοιχεία μπορούν να παρέχονται στην Magyar Suzuki Corporation όπου οι υπηρεσίες μπορούν να παρέχονται από αυτήν.<br />
            (π.χ. συνδεδεμένο σύστημα Suzuki)<br />
            Η σημείωση Απορρήτου της Magyar Suzuki Corporation είναι εδώ.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="hu" style="display:none">
            Az itt megadott személyes adatok felhasználására kizárólag a rendszer irányításával és használatával kapcsolatban levélben,
            telefonon és e-mailben történő információszolgáltatás céljából kerül sor a személyes adatok kezelésére vonatkozó adatvédelmi nyilatkozat és/vagy az adatvédelmi irányelvei rendelkezéseinek megfelelően.<br /><br />
            A személyes adatok kezelésére vonatkozó adatvédelmi nyilatkozat (EGT-ben élő felhasználók számára) (angol nyelven):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            A Suzuki Motor Corporation adatvédelmi irányelvei (EGT-n kívüli felhasználók számára) (angol és más nyelveken):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*Előfordulhat, hogy az Európai Gazdasági Térségben (EGT) a Magyar Suzuki Zrt. kezeli a személyes adatokat és nyújtja a szolgáltatásokat.<br />
            (pl. Suzuki csatlakoztatott rendszer)<br />
            A Magyar Suzuki Zrt. adatvédelmi nyilatkozata alább olvasható.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br />-->
          </p>
          <p class="langCng" lang="it" style="display:none">
            Le informazioni personali qui inserite verranno utilizzate esclusivamente allo scopo di fornire informazioni sulla gestione e il funzionamento del sistema tramite posta,
            telefono ed e-mail e in conformità con Informativa sulla privacy per il trattamento dei dati personali e/o le politiche sulla privacy che seguono.<br /><br />
            Informativa sulla privacy per il trattamento dei dati personali (per gli utenti del SEE) (in inglese):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Le nostre politiche sulla privacy (per gli utenti al di fuori del SEE) (in inglese, ecc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*In EEA, le informazioni personali possono essere fornite a Magyar Suzuki Corporation e possono essere forniti servizi.<br />
            (ad es. Suzuki Connected System)<br />
            L'Informativa sulla privacy di Magyar Suzuki Corporation si trova qui.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="no" style="display:none">
            The personal information entered herein will be used exclusively for the purpose of providing information regarding management and operation of the system by mail,
            telephone and e-mail, and in accordance with Privacy Notice for processing personal data and/or Privacy Policies below.<br /><br />
            Privacy Notice for processing personal data (For users in the EEA) (in English):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Our Privacy Policies (For users outside the EEA) (in English, etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*In EEA, the personal information may be provided to Magyar Suzuki Corporation where services may be provided by it.<br />
            (e.g. Suzuki Connected System)<br />
            The Magyar Suzuki Corporation Privacy Notice is here.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="pl" style="display:none">
            Dane osobowe wprowadzone w niniejszym dokumencie będą wykorzystywane wyłącznie w celu dostarczania informacji dotyczących zarządzania i obsługi systemu za pośrednictwem poczty,
            telefonu i poczty e-mail oraz zgodnie z Informacje o ochronie prywatności dotyczące przetwarzania danych osobowych i/lub Poniższe zasady ochrony prywatności.<br /><br />
            Polityka prywatności dotycząca przetwarzania danych osobowych (dla użytkowników w EOG) (w języku angielskim):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Nasze polityki prywatności (dla użytkowników spoza EOG) (w języku angielskim itp.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*W EOG dane osobowe mogą być przekazywane do Magyar Suzuki Corporation i mogą być świadczone usługi.<br />
            (np. Suzuki Connected System)<br />
            Informacja o polityce prywatności firmy Magyar Suzuki Corporation znajduje się tutaj.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="pt" style="display:none">
            As informações pessoais aqui introduzidas serão utilizadas exclusivamente com a finalidade de fornecer informações relativas à gestão e funcionamento do sistema por correio,
            telefone e e-mail, e de acordo com Aviso de Privacidade para o processamento de dados pessoais e/ou a Política de Privacidade da abaixo.<br /><br />
            Avisos de privacidade para processar dados pessoais (para utilizadores no EEE) (em inglês):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation As nossas políticas de privacidade (para utilizadores fora do EEE) (em inglês, etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*No EEE, as informações pessoais podem ser fornecidas à Magyar Suzuki Corporation e os serviços podem ser fornecidos.<br />
            (por exemplo, Sistema Ligado Suzuki)<br />
            O Aviso de Privacidade da Corporação Magyar Suzuki está aqui.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="ro" style="display:none">
            Datele cu caracter personal introduse aici vor fi utilizate exclusiv în scopul furnizării de informații privind gestionarea și funcționarea sistemului prin poștă,
            telefon și e-mail și în conformitate cu Notificarea de confidențialitate pentru prelucrarea datelor cu caracter personal și/sau Politicile noastre de confidențialitate ale de mai jos.<br /><br />
            Notificare de confidențialitate pentru prelucrarea datelor cu caracter personal (pentru utilizatorii din SEE) (în engleză):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation Politicile noastre de confidențialitate (pentru utilizatorii din afara SEE) (în engleză, etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*În SEE, datele cu caracter personal pot fi furnizate către Magyar Suzuki Corporation și pot fi furnizate servicii.<br />
            (de exemplu, Suzuki Connected System)<br />
            Declarația de confidențialitate din partea Magyar Suzuki Corporation este aici.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="sk" style="display:none">
            Osobné údaje zadané do systému sa budú používať výhradne na poskytovanie informácií týkajúcich sa správy a prevádzky systému poštou,
            telefonicky a e-mailom, a v súlade so Oznámenie o ochrane a spracovávaní osobných údajov a/alebo Našou politikou na ochranu osobných údajov, ktorá je uvedená nižšie.<br /><br />
            Oznámenie o ochrane a spracovávaní osobných údajov (pre používateľov v EEA) (v angličtine):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Naša politika na ochranu osobných údajov Suzuki Motor Corporation (pre používateľov mimo EEA) (v angličtine atď.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--* V krajinách EEA môžu byť osobné údaje poskytované spoločnosti Magyar Suzuki Corporation a poskytované môžu byť služby.<br />
            (napr. Suzuki Connected System)<br />
            Oznámenie o ochrane osobných údajov Magyar Suzuki Corporation.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="sv" style="display:none">
            Personuppgifterna som anges häri kommer endast att användas för att tillhandahålla information om systemets hantering och funktion via post,
            telefon och e-post, och i enlighet med integritetsmeddelandet för behandling av personuppgifter och/eller integritetspolicyer nedan.<br /><br />
            Integritetsmeddelande för behandling av personuppgifter (för användare inom EEA) (på engelska):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation – våra integritetspolicyer (för användare utanför EEA) (på engelska osv.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--* Inom EEA kan Magyar Suzuki Corporation ta del av personuppgifter och tillhandahålla tjänster.<br />
            (t.ex. Suzuki Connected System)<br />
            Du hittar Magyar Suzuki Corporations integritetsmeddelande här:<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
          <p class="langCng" lang="he" style="display:none">
            המידע האישי שיוזן כאן ישמש אך ורק למטרת מסירת מידע בנוגע לניהול ותפעול המערכת בדואר, בטלפון ובדואר אלקטרוני, ובהתאם להודעת הפרטיות לעיבוד נתונים אישיים ו/או מדיניות הפרטיות שלהלן.<br /><br />
            הודעת פרטיות לעיבוד נתונים אישיים (עבור משתמשים ב-EEA) (באנגלית):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            Suzuki Motor Corporation מדיניות הפרטיות שלנו (עבור משתמשים מחוץ לאזור ה-EEA) (באנגלית וכו'):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
            <!--*In EEA, the personal information may be provided to Magyar Suzuki Corporation where services may be provided by it.<br />
            (e.g. Suzuki Connected System)<br />
            The Magyar Suzuki Corporation Privacy Notice is here.<br />
            <a href="https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf" target="_blank" rel="noopener noreferrer">https://webedi.suzuki.hu/webedi/privacy/msc_ssn_privacy_notice.pdf</a><br /><br /> -->
          </p>
        </td>
      </tr>
    </table>
    <div id="fotter">
      <p>©SUZUKI MOTOR CORPORATION</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { API } from '@aws-amplify/api'
import awsconfig from '../aws-exports.js'
API.configure(awsconfig)
export default {
  name: 'app',
  components: {
  },
  date () {
    return {
      apiName: 'confirmstapi',
      path: '/check',
      timestamp: null,
      ans: null,
      tc: null
    }
  },
  // --- イベント関数 ---
  methods: {
    langSet: function (argLang) {
      var elm = document.getElementsByClassName('langCng')
      for (var i = 0; i < elm.length; i++) {
        // --- 選択された言語と一致は表示、その他は非表示 -------
        if (elm[i].getAttribute('lang') === argLang) {
          elm[i].style.display = ''
        } else {
          elm[i].style.display = 'none'
        }
      }
    },
    // --- はい(Yes)を押下した場合 ---
    postYes: function () {
      if (this.checkToken()) {
        // --- tokenの末尾がAの時日本語にする---
        var str = this.$route.query.tc.substr(17, 1)
        const apiName = 'confirmstapi'
        const path = '/check'
        // --- オプション ---
        const myInit = {
          headers: {
            'Content-Type': 'application/json'
          },
          response: true,
          body: {
            timestamp: String(this.dateFormat),
            ans: Number('1'),
            tc: String(this.$route.query.tc)
          }
        }
        // --- データ登録 ---
        API.post(apiName, path, myInit).then(response => {
          console.log(response)
          switch (str) {
            case 'A':
              this.$router.replace({ path: '/checkok', query: { la: 'ja' } })
              break
            case 'B':
              this.$router.replace({ path: '/checkok', query: { la: 'en' } })
              break
            case 'C':
              this.$router.replace({ path: '/checkok', query: { la: 'cs' } })
              break
            case 'D':
              this.$router.replace({ path: '/checkok', query: { la: 'da' } })
              break
            case 'E':
              this.$router.replace({ path: '/checkok', query: { la: 'nl' } })
              break
            case 'F':
              this.$router.replace({ path: '/checkok', query: { la: 'es' } })
              break
            case 'G':
              this.$router.replace({ path: '/checkok', query: { la: 'fr' } })
              break
            case 'H':
              this.$router.replace({ path: '/checkok', query: { la: 'de' } })
              break
            case 'I':
              this.$router.replace({ path: '/checkok', query: { la: 'el' } })
              break
            case 'J':
              this.$router.replace({ path: '/checkok', query: { la: 'hu' } })
              break
            case 'K':
              this.$router.replace({ path: '/checkok', query: { la: 'it' } })
              break
            case 'L':
              this.$router.replace({ path: '/checkok', query: { la: 'no' } })
              break
            case 'M':
              this.$router.replace({ path: '/checkok', query: { la: 'pl' } })
              break
            case 'N':
              this.$router.replace({ path: '/checkok', query: { la: 'pt' } })
              break
            case 'O':
              this.$router.replace({ path: '/checkok', query: { la: 'ro' } })
              break
            case 'P':
              this.$router.replace({ path: '/checkok', query: { la: 'sk' } })
              break
            case 'Q':
              this.$router.replace({ path: '/checkok', query: { la: 'sv' } })
              break
            case 'R':
              this.$router.replace({ path: '/checkok', query: { la: 'he' } })
              break
            default:
              this.$router.replace({ path: '/checkok', query: { la: 'en' } })
              break
          }
        }).catch(error => {
          console.log(error)
          switch (str) {
            case 'A':
              this.$router.replace({ path: '/posterror', query: { la: 'ja' } })
              break
            case 'B':
              this.$router.replace({ path: '/posterror', query: { la: 'en' } })
              break
            case 'C':
              this.$router.replace({ path: '/posterror', query: { la: 'cs' } })
              break
            case 'D':
              this.$router.replace({ path: '/posterror', query: { la: 'da' } })
              break
            case 'E':
              this.$router.replace({ path: '/posterror', query: { la: 'nl' } })
              break
            case 'F':
              this.$router.replace({ path: '/posterror', query: { la: 'es' } })
              break
            case 'G':
              this.$router.replace({ path: '/posterror', query: { la: 'fr' } })
              break
            case 'H':
              this.$router.replace({ path: '/posterror', query: { la: 'de' } })
              break
            case 'I':
              this.$router.replace({ path: '/posterror', query: { la: 'el' } })
              break
            case 'J':
              this.$router.replace({ path: '/posterror', query: { la: 'hu' } })
              break
            case 'K':
              this.$router.replace({ path: '/posterror', query: { la: 'it' } })
              break
            case 'L':
              this.$router.replace({ path: '/posterror', query: { la: 'no' } })
              break
            case 'M':
              this.$router.replace({ path: '/posterror', query: { la: 'pl' } })
              break
            case 'N':
              this.$router.replace({ path: '/posterror', query: { la: 'pt' } })
              break
            case 'O':
              this.$router.replace({ path: '/posterror', query: { la: 'ro' } })
              break
            case 'P':
              this.$router.replace({ path: '/posterror', query: { la: 'sk' } })
              break
            case 'Q':
              this.$router.replace({ path: '/posterror', query: { la: 'sv' } })
              break
            case 'R':
              this.$router.replace({ path: '/posterror', query: { la: 'he' } })
              break
            default:
              this.$router.replace({ path: '/posterror', query: { la: 'en' } })
              break
          }
        })
      }
    },
    // --- いいえ(No)を押下した場合 ---
    postNo: function () {
      if (this.checkToken()) {
        // --- tokenの末尾がAの時日本語にする---
        var str = this.$route.query.tc.substr(17, 1)

        const apiName = 'confirmstapi'
        const path = '/check'
        // --- オプション ---
        const myInit = {
          headers: {
            'Content-Type': 'application/json'
          },
          response: true,
          body: {
            timestamp: String(this.dateFormat),
            ans: Number('0'),
            tc: String(this.$route.query.tc)
          }
        }
        // --- データ登録 ---
        API.post(apiName, path, myInit).then(response => {
          console.log(response)
          switch (str) {
            case 'A':
              this.$router.replace({ path: '/checkno', query: { la: 'ja' } })
              break
            case 'B':
              this.$router.replace({ path: '/checkno', query: { la: 'en' } })
              break
            case 'C':
              this.$router.replace({ path: '/checkno', query: { la: 'cs' } })
              break
            case 'D':
              this.$router.replace({ path: '/checkno', query: { la: 'da' } })
              break
            case 'E':
              this.$router.replace({ path: '/checkno', query: { la: 'nl' } })
              break
            case 'F':
              this.$router.replace({ path: '/checkno', query: { la: 'es' } })
              break
            case 'G':
              this.$router.replace({ path: '/checkno', query: { la: 'fr' } })
              break
            case 'H':
              this.$router.replace({ path: '/checkno', query: { la: 'de' } })
              break
            case 'I':
              this.$router.replace({ path: '/checkno', query: { la: 'el' } })
              break
            case 'J':
              this.$router.replace({ path: '/checkno', query: { la: 'hu' } })
              break
            case 'K':
              this.$router.replace({ path: '/checkno', query: { la: 'it' } })
              break
            case 'L':
              this.$router.replace({ path: '/checkno', query: { la: 'no' } })
              break
            case 'M':
              this.$router.replace({ path: '/checkno', query: { la: 'pl' } })
              break
            case 'N':
              this.$router.replace({ path: '/checkno', query: { la: 'pt' } })
              break
            case 'O':
              this.$router.replace({ path: '/checkno', query: { la: 'ro' } })
              break
            case 'P':
              this.$router.replace({ path: '/checkno', query: { la: 'sk' } })
              break
            case 'Q':
              this.$router.replace({ path: '/checkno', query: { la: 'sv' } })
              break
            case 'R':
              this.$router.replace({ path: '/checkno', query: { la: 'he' } })
              break
            default:
              this.$router.replace({ path: '/checkno', query: { la: 'en' } })
              break
          }
        }).catch(error => {
          console.log(error)
          switch (str) {
            case 'A':
              this.$router.replace({ path: '/posterror', query: { la: 'ja' } })
              break
            case 'B':
              this.$router.replace({ path: '/posterror', query: { la: 'en' } })
              break
            case 'C':
              this.$router.replace({ path: '/posterror', query: { la: 'cs' } })
              break
            case 'D':
              this.$router.replace({ path: '/posterror', query: { la: 'da' } })
              break
            case 'E':
              this.$router.replace({ path: '/posterror', query: { la: 'nl' } })
              break
            case 'F':
              this.$router.replace({ path: '/posterror', query: { la: 'es' } })
              break
            case 'G':
              this.$router.replace({ path: '/posterror', query: { la: 'fr' } })
              break
            case 'H':
              this.$router.replace({ path: '/posterror', query: { la: 'de' } })
              break
            case 'I':
              this.$router.replace({ path: '/posterror', query: { la: 'el' } })
              break
            case 'J':
              this.$router.replace({ path: '/posterror', query: { la: 'hu' } })
              break
            case 'K':
              this.$router.replace({ path: '/posterror', query: { la: 'it' } })
              break
            case 'L':
              this.$router.replace({ path: '/posterror', query: { la: 'no' } })
              break
            case 'M':
              this.$router.replace({ path: '/posterror', query: { la: 'pl' } })
              break
            case 'N':
              this.$router.replace({ path: '/posterror', query: { la: 'pt' } })
              break
            case 'O':
              this.$router.replace({ path: '/posterror', query: { la: 'ro' } })
              break
            case 'P':
              this.$router.replace({ path: '/posterror', query: { la: 'sk' } })
              break
            case 'Q':
              this.$router.replace({ path: '/posterror', query: { la: 'sv' } })
              break
            case 'R':
              this.$router.replace({ path: '/posterror', query: { la: 'he' } })
              break
            default:
              this.$router.replace({ path: '/posterror', query: { la: 'en' } })
              break
          }
        })
      }
    },
    // --- ボタンクリック時のtcの桁数をチェック ---
    checkToken: function () {
      if (this.$route.query.tc.length === 18) {
        return true
      } else {
        this.$router.replace('/invalid')
      }
    }
  },
  // --- 計算用関数 --
  computed: {
    // 日付フォーマット変更
    dateFormat: function () {
      var now = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
      var YYYY = now.getFullYear()
      var MM = ('0' + (now.getMonth() + 1)).slice(-2)
      var DD = ('0' + now.getDate()).slice(-2)
      var HH = ('0' + now.getHours()).slice(-2)
      var mm = ('0' + now.getMinutes()).slice(-2)
      var ss = ('0' + now.getSeconds()).slice(-2)
      var format = YYYY + MM + DD + HH + mm + ss
      return format
    }
  },
  // --- 画面読み込み時 ---
  mounted: function () {
    // tcのクエリがない、もしくはtcが18ケタない場合、無効ページに遷移
    if (this.$route.query.tc === null || this.$route.query.tc.length !== 18) {
      this.$router.replace('/invalid')
    }
    // --- ブラウザの設定言語を取得 (無効化)---
    var wDef = (window.navigator.browserLanguage || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage).substr(0, 2)
    // --- tokenの末尾1桁目を取得する---
    // A : 日本語(ja)
    // B : 英語(en)
    // C：チェコ語(cs)
    // D：デンマーク語(da)
    // E：オランダ語(nl)
    // F：スペイン語(es)
    // G：フランス語(fr)
    // H：ドイツ語(de)
    // I：ギリシャ語(el)
    // J：ハンガリー語(hu)
    // K：イタリア語(it)
    // L：ノルウェー語(no)
    // M：ポーランド語(pl)
    // N：ポルトガル語(pt)
    // O：ルーマニア語(ro)
    // P：スロバキア語(sk)
    // Q：スウェーデン語(sv)
    // R：ヘブライ語(he)
    // デフォルト：英語(en)
    const app = document.getElementById('app')
    var str = this.$route.query.tc.substr(17, 1)
    switch (str) {
      case 'A':
        wDef = 'ja'
        break
      case 'B':
        wDef = 'en'
        break
      case 'C':
        wDef = 'cs'
        break
      case 'D':
        wDef = 'da'
        break
      case 'E':
        wDef = 'nl'
        break
      case 'F':
        wDef = 'es'
        break
      case 'G':
        wDef = 'fr'
        break
      case 'H':
        wDef = 'de'
        break
      case 'I':
        wDef = 'el'
        break
      case 'J':
        wDef = 'hu'
        break
      case 'K':
        wDef = 'it'
        break
      case 'L':
        wDef = 'no'
        break
      case 'M':
        wDef = 'pl'
        break
      case 'N':
        wDef = 'pt'
        break
      case 'O':
        wDef = 'ro'
        break
      case 'P':
        wDef = 'sk'
        break
      case 'Q':
        wDef = 'sv'
        break
      case 'R':
        wDef = 'he'
        app.classList.add('rtl')
        break
      default:
        wDef = 'en'
        break
    }
    // --- tokenの末尾2桁目を取得する---
    // A : 購買システム
    // B : 販売店システム
    // C : (EXUM)SSO連携
    var str2 = this.$route.query.tc.substr(16, 1)
    // tokenの末尾2桁目が該当以外であれば、無効ページに遷移
    if (str2 !== 'A' && str2 !== 'B' && str2 !== 'C') {
      this.$router.replace('/invalid')
    }
    if (str2 === 'A') {
      this.$router.replace({ path: '/Home', query: { tc: this.$route.query.tc } })
    } else if (str2 === 'C') {
      this.$router.replace({ path: '/HomeC', query: { tc: this.$route.query.tc } })
    }
    // --- 日本語か英語を表示させる ---
    this.langSet(wDef)
    var btn = document.getElementsByName('langKbn')
    for (var i = 0; i < btn.length; i++) {
      // --- 選択された言語のボタンをチェック状態にする ---
      if (btn[i].getAttribute('id') === 's' + wDef) {
        btn[i].checked = true
      } else {
        btn[i].checked = false
      }
    }
  }
}
</script>
<style type="text/css">
  label {
    width: 200px;
    margin:0 10px 0 0;
  }
  button.langCng  {
    margin:0 5px 0 0;
    padding:10px 10px 10px 10px;
    width: 100px;
  }
  button.langCng:hover  {
    background: hsla(180,50%,50%,0.7);
  }
  /* === ラジオボタンは非表示 ================== */
  #app input[type="radio"]{
    display:none;
  }
  #app label{
    display    : inline-block;
    border     : 1px solid #ccc;
    box-shadow : 2px 2px #999;
    padding    : 2px 6px;
  }
 /* === 選択されている言語のラベル色を変更 ==== */
  #app input[type="radio"]:checked + label {
    background : #ffa64d
  }
  .msg {
    text-align:left
  }
  #notice {
    border: double 5px black;
    background-color: #E0E0E0;
    width: 700px;
  }
  .rtl {
    direction: rtl;
    unicode-bidi: embed;
  }
</style>
