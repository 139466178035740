<template>
  <div id="app">
    <h1 class="langCng" lang="ja" style="display:none;">【検証】【スズキ株式会社】統合認証システム利用確認ページ</h1>
    <h1 class="langCng" lang="en" >[ST][SUZUKI MOTOR CORPORATION] The check request of your e-mail address for using SSO system</h1>
    <table align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none;">
      上記システムへのユーザー登録または、メールアドレス変更の申請を受け付けました。<br />
      申請いただいたメールアドレスが無効ですと、システムからのご連絡が届かず、<br />
      機能を利用することができません。<br /><br />
      つきましては、メールアドレスが有効であるかどうかの確認をさせていただきますので、<br />
      お手数ですが、以下の操作をお願いします。<br /><br />
      ご案内させていただいたメールの宛先が、正しい場合は「はい」のボタンを、<br />
      申請した覚えがない場合、またはメール内容に心当たりがない場合は<br />
      「いいえ」のボタンを押してください。<br /><br />
      </p>
      <p class="langCng" lang="en" >
        The application to the system has been accepted for the user's registration or the Email-address change.<br />
      When the applied Email-address is invalid, you cannot receive message from us.<br />
      Therefore, please operate as follows whether the Email-address is correct for our check.<br /><br />
      When the address of the sent Email is correct, please click 'Yes' button.<br />
      When it is wrong or there is no remembrance in the content of Email, please click 'No' button.<br /><br />
      </p>
        </td>
      </tr>
    </table>
    <button class="langCng" lang="ja" style="display:none;" v-on:click="postYes">はい</button>
    <button class="langCng" lang="ja" style="display:none;" v-on:click="postNo">いいえ</button>
    <button class="langCng" lang="en"  v-on:click="postYes">Yes</button>
    <button class="langCng" lang="en"  v-on:click="postNo">No</button>
    <br /><br />
    <table id="notice" align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none;">
            ご登録いただきました個人情報は、スズキ㈱個人情報基本方針、スズキプライバシーノーティス 及び/または スズキプライバシーポリシーに則り、
            郵便・電話・電子メールの方法によりシステムの管理運用上の案内を提供する目的に限り使用します。<br /><br />
            i)スズキ㈱個人情報基本方針（日本のお取引先様用）(日本語):<br />
            <a href="https://www.suzuki.co.jp/privacy_statement/" target="_blank" rel="noopener noreferrer">https://www.suzuki.co.jp/privacy_statement/</a><br />
            ii)スズキプライバシーノーティス（EEA域内のお取引先様用）(英語):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            iii)スズキプライバシーポリシー（その他地域のお取引先様用）(英語等):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
          </p>
          <p class="langCng" lang="en" >
            The personal information entered herein will be used exclusively for the purpose of providing information regarding management and operation of the system by mail,
            telephone and e-mail, and in accordance with i) the basic policy of Suzuki Motor Corporation for personal information,
            ii) Privacy Notice for processing personal data and/or iii) Suzuki Motor Corporation's Our Privacy Policies below.<br /><br />
            i)Basic policy of Suzuki Motor Corporation for personal information (for Japanese suppliers) (in Japanese):<br />
            <a href="https://www.suzuki.co.jp/privacy_statement/" target="_blank" rel="noopener noreferrer">https://www.suzuki.co.jp/privacy_statement/</a><br />
            ii)Privacy Notice for processing personal data (for EEA suppliers) (in English):<br />
            <a href="https://www.globalsuzuki.com/img/privacynotice.pdf" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/img/privacynotice.pdf</a><br />
            iii)Suzuki Motor Corporation Our Privacy Policies (for other suppliers) (in English, etc.):<br />
            <a href="https://www.globalsuzuki.com/cookies/index.html" target="_blank" rel="noopener noreferrer">https://www.globalsuzuki.com/cookies/index.html</a><br /><br />
          </p>
        </td>
      </tr>
    </table>
    <div id="fotter">
      <p>©SUZUKI MOTOR CORPORATION</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { API } from '@aws-amplify/api'
import awsconfig from '../aws-exports.js'
API.configure(awsconfig)

export default {
  name: 'app',
  components: {
  },
  date () {
    return {
      apiName: 'confirmstapi',
      path: '/check',
      timestamp: null,
      ans: null,
      tc: null
    }
  },
  // --- イベント関数 ---
  methods: {
    langSet: function (argLang) {
      var elm = document.getElementsByClassName('langCng')
      for (var i = 0; i < elm.length; i++) {
        // --- 選択された言語と一致は表示、その他は非表示 -------
        if (elm[i].getAttribute('lang') === argLang) {
          elm[i].style.display = ''
        } else {
          elm[i].style.display = 'none'
        }
      }
    },
    // --- はい(Yes)を押下した場合 ---
    postYes: function () {
      if (this.checkToken()) {
        // --- tokenの末尾がAの時日本語にする---
        var str = this.$route.query.tc.substr(17, 1)
        const apiName = 'confirmstapi'
        const path = '/check'
        // --- オプション ---
        const myInit = {
          headers: {
            'Content-Type': 'application/json'
          },
          response: true,
          body: {
            timestamp: String(this.dateFormat),
            ans: Number('1'),
            tc: String(this.$route.query.tc)
          }
        }
        // --- データ登録 ---
        API.post(apiName, path, myInit).then(response => {
          console.log(response)
          if (str === 'A') {
            this.$router.replace({ path: '/checkok', query: { la: 'ja' } })
          } else {
            this.$router.replace({ path: '/checkok', query: { la: 'en' } })
          }
        }).catch(error => {
          console.log(error)
          if (str === 'A') {
            this.$router.replace({ path: '/posterror', query: { la: 'ja' } })
          } else {
            this.$router.replace({ path: '/posterror', query: { la: 'en' } })
          }
        })
      }
    },
    // --- いいえ(No)を押下した場合 ---
    postNo: function () {
      if (this.checkToken()) {
        // --- tokenの末尾がAの時日本語にする---
        var str = this.$route.query.tc.substr(17, 1)
        const apiName = 'confirmstapi'
        const path = '/check'
        // --- オプション ---
        const myInit = {
          headers: {
            'Content-Type': 'application/json'
          },
          response: true,
          body: {
            timestamp: String(this.dateFormat),
            ans: Number('0'),
            tc: String(this.$route.query.tc)
          }
        }
        // --- データ登録 ---
        API.post(apiName, path, myInit).then(response => {
          console.log(response)
          if (str === 'A') {
            this.$router.replace({ path: '/checkno', query: { la: 'ja' } })
          } else {
            this.$router.replace({ path: '/checkno', query: { la: 'en' } })
          }
        }).catch(error => {
          console.log(error)
          if (str === 'A') {
            this.$router.replace({ path: '/posterror', query: { la: 'ja' } })
          } else {
            this.$router.replace({ path: '/posterror', query: { la: 'en' } })
          }
        })
      }
    },
    // --- ボタンクリック時のtcの桁数をチェック ---
    checkToken: function () {
      if (this.$route.query.tc.length === 18) {
        return true
      } else {
        this.$router.replace('/invalid')
      }
    }
  },
  // --- 計算用関数 --
  computed: {
    // 日付フォーマット変更
    dateFormat: function () {
      var now = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
      var YYYY = now.getFullYear()
      var MM = ('0' + (now.getMonth() + 1)).slice(-2)
      var DD = ('0' + now.getDate()).slice(-2)
      var HH = ('0' + now.getHours()).slice(-2)
      var mm = ('0' + now.getMinutes()).slice(-2)
      var ss = ('0' + now.getSeconds()).slice(-2)
      var format = YYYY + MM + DD + HH + mm + ss
      return format
    }
  },
  // --- 画面読み込み時 ---
  mounted: function () {
    // tcのクエリがない、もしくはtcが18ケタない場合、無効ページに遷移
    if (this.$route.query.tc === null || this.$route.query.tc.length !== 18) {
      this.$router.replace('/invalid')
    }
    // --- ブラウザの設定言語を取得 (無効化)---
    var wDef = (window.navigator.browserLanguage || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage).substr(0, 2)
    // --- tokenの末尾1桁目を取得する---
    // A : 日本語(ja)
    // B : 英語(en)
    // C：チェコ語(cs)
    // D：デンマーク語(da)
    // E：オランダ語(nl)
    // F：スペイン語(es)
    // G：フランス語(fr)
    // H：ドイツ語(de)
    // I：ギリシャ語(el)
    // J：ハンガリー語(hu)
    // K：イタリア語(it)
    // L：ノルウェー語(no)
    // M：ポーランド語(pl)
    // N：ポルトガル語(pt)
    // O：ルーマニア語(ro)
    // P：スロバキア語(sk)
    // Q：スウェーデン語(sv)
    // デフォルト：英語(en)
    var str = this.$route.query.tc.substr(17, 1)
    switch (str) {
      case 'A':
        wDef = 'ja'
        break
      case 'B':
        wDef = 'en'
        break
      default:
        wDef = 'en'
        break
    }
    // --- tokenの末尾2桁目を取得する---
    // A : 購買システム
    // B : 販売店システム
    // C : (EXUM)SSO連携
    var str2 = this.$route.query.tc.substr(16, 1)
    // tokenの末尾2桁目が該当以外であれば、無効ページに遷移
    if (str2 !== 'A' && str2 !== 'B' && str2 !== 'C') {
      this.$router.replace('/invalid')
    }
    if (str2 === 'A') {
      this.$router.replace({ path: '/Home', query: { tc: this.$route.query.tc } })
    } else if (str2 === 'B') {
      this.$router.replace({ path: '/HomeB', query: { tc: this.$route.query.tc } })
    }
    // --- 日本語か英語を表示させる ---
    this.langSet(wDef)
    var btn = document.getElementsByName('langKbn')
    for (var i = 0; i < btn.length; i++) {
      // --- 選択された言語のボタンをチェック状態にする ---
      if (btn[i].getAttribute('id') === 's' + wDef) {
        btn[i].checked = true
      } else {
        btn[i].checked = false
      }
    }
  }
}
</script>
<style type="text/css">
  label {
    width: 200px;
    margin:0 10px 0 0;
  }
  button.langCng  {
    margin:0 5px 0 0;
    padding:10px 10px 10px 10px;
    width: 100px;
  }
  button.langCng:hover  {
    background: hsla(180,50%,50%,0.7);
  }
  /* === ラジオボタンは非表示 ================== */
  #app input[type="radio"]{
    display:none;
  }
  #app label{
    display    : inline-block;
    border     : 1px solid #ccc;
    box-shadow : 2px 2px #999;
    padding    : 2px 6px;
  }
 /* === 選択されている言語のラベル色を変更 ==== */
  #app input[type="radio"]:checked + label {
    background : #ffa64d;
  }
  .msg {
    text-align:left;
  }
  #notice {
    border: double 5px black;
    background-color: #E0E0E0;
    width: 700px;
  }
</style>
