<template>
  <div id="CheckNO">
    <table align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none;">
              <font class="complete">確認が完了いたしました。</font><br /><br /><br />
              システムよりご連絡いたしますので、<br />
              今しばらくお待ちいただくようお願いいたします。<br />
              ご協力ありがとうございました。<br /><br />
              このページを閉じてください。<br />
          </p>
          <p class="langCng" lang="en" >
            <font class="complete">The operation was finished.</font><br /><br /><br />
            Thank you for your operation.<br />
            Please wait a little for the contact from us.<br /><br />
            Please close this page.<br />
          </p>
          <p class="langCng" lang="cs" style="display:none;">
            <font class="complete">Operace byla dokončena.</font><br /><br /><br />
            Děkujeme za vaši operaci.<br />
            Počkejte prosím chvíli na kontakt od nás.<br /><br />
            Zavřete prosím tuto stránku.<br />
          </p>
          <p class="langCng" lang="da" style="display:none;">
            <font class="complete">Operationen var afsluttet.</font><br /><br /><br />
            Tak for din operation.<br />
            Vent venligst lidt på kontakten fra os.<br /><br />
            Luk venligst denne side.<br />
          </p>
          <p class="langCng" lang="nl" style="display:none;">
            <font class="complete">De operatie was afgelopen.</font><br /><br /><br />
            Bedankt voor je operatie.<br />
            Wacht nog even op het contact van ons.<br /><br />
            Sluit deze pagina.<br />
          </p>
          <p class="langCng" lang="es" style="display:none;">
            <font class="complete">La operación estaba terminada.</font><br /><br /><br />
            Gracias por su operación.<br />
            Por favor espere un poco por nuestro contacto.<br /><br />
            Cierra esta página.<br />
          </p>
          <p class="langCng" lang="fr" style="display:none;">
            <font class="complete">L'opération était terminée.</font><br /><br /><br />
            Merci pour votre opération.<br />
            Veuillez patienter un peu pour le contact de nous.<br /><br />
            Veuillez fermer cette page.<br />
          </p>
          <p class="langCng" lang="de" style="display:none;">
            <font class="complete">Die Operation war beendet.</font><br /><br /><br />
            Vielen Dank für Ihre Operation.<br />
            Bitte warten Sie etwas auf die Kontaktaufnahme von uns.<br /><br />
            Bitte schließen Sie diese Seite.<br />
          </p>
          <p class="langCng" lang="el" style="display:none;">
            <font class="complete">Η επέμβαση ολοκληρώθηκε.</font><br /><br /><br />
            Σας ευχαριστούμε για την επέμβαση σας.<br />
            Περιμένετε λίγο για την επικοινωνία από εμάς.<br /><br />
            Παρακαλώ κλείστε αυτή τη σελίδα.<br />
          </p>
          <p class="langCng" lang="hu" style="display:none;">
            <font class="complete">A művelet befejeződött.</font><br /><br /><br />
            Köszönjük működését.<br />
            Kérjük, várjon egy kicsit a kapcsolatfelvételre.<br /><br />
            Kérjük, zárja be ezt az oldalt.<br />
          </p>
          <p class="langCng" lang="it" style="display:none;">
            <font class="complete">L'operazione era terminata.</font><br /><br /><br />
            Grazie per la tua operazione.<br />
            Si prega di attendere un po' per il nostro contatto.<br /><br />
            Si prega di chiudere questa pagina.<br />
          </p>
          <p class="langCng" lang="no" style="display:none;">
            <font class="complete">Operasjonen var ferdig.</font><br /><br /><br />
            Takk for operasjonen.<br />
            Vent litt på kontakten fra oss.<br /><br />
            Vennligst lukk denne siden.<br />
          </p>
          <p class="langCng" lang="pl" style="display:none;">
            <font class="complete">Operacja została zakończona.</font><br /><br /><br />
            Dziękuję za operację.<br />
            Poczekaj chwilę na kontakt od nas.<br /><br />
            Zamknij tę stronę.<br />
          </p>
          <p class="langCng" lang="pt" style="display:none;">
            <font class="complete">A operação foi concluída.</font><br /><br /><br />
            Obrigado por sua operação.<br />
            Por favor, aguarde um pouco pelo nosso contato.<br /><br />
            Por favor, feche esta página.<br />
          </p>
          <p class="langCng" lang="ro" style="display:none;">
            <font class="complete">Operația a fost terminată.</font><br /><br /><br />
            Vă mulțumim pentru operațiunea dvs.<br />
            Vă rugăm să așteptați puțin contactul de la noi.<br /><br />
            Vă rugăm să închideți această pagină.<br />
          </p>
          <p class="langCng" lang="sk" style="display:none;">
            <font class="complete">Operácia bola ukončená.</font><br /><br /><br />
            Ďakujeme za operáciu.<br />
            Počkajte prosím chvíľu na kontakt od nás.<br /><br />
            Zatvorte prosím túto stránku.<br />
          </p>
          <p class="langCng" lang="sv" style="display:none;">
            <font class="complete">Operationen var avslutad.</font><br /><br /><br />
            Tack för din operation.<br />
            Vänta lite på kontakten från oss.<br /><br />
            Stäng den här sidan.<br />
          </p>
          <p class="langCng rtl" lang="he" style="display:none;">
            <font class="complete">המבצע הסתיים.</font><br /><br /><br />
            תודה על המבצע.<br />
            אנא המתן מעט ליצירת קשר מאיתנו.<br /><br />
            נא לסגור את הדף הזה.<br />
          </p>
        </td>
      </tr>
    </table>
    <div id="fotter">
      <p>©SUZUKI MOTOR CORPORATION</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'CheckNO',
  components: {
  },
  methods: {
    langSet: function (argLang) {
      var elm = document.getElementsByClassName('langCng')
      for (var i = 0; i < elm.length; i++) {
        // --- 選択された言語と一致は表示、その他は非表示 -------
        if (elm[i].getAttribute('lang') === argLang) {
          elm[i].style.display = ''
        } else {
          elm[i].style.display = 'none'
        }
      }
    }
  },
  mounted: function () {
    // ブラウザの言語を取得
    var wDef = (window.navigator.browserLanguage || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage).substr(0, 2)
    wDef = this.$route.query.la
    this.langSet(wDef)
    var btn = document.getElementsByName('langKbn')
    for (var i = 0; i < btn.length; i++) {
      // --- 選択された言語のボタンをチェック状態にする -------
      if (btn[i].getAttribute('id') === 's' + wDef) {
        btn[i].checked = true
      } else {
        btn[i].checked = false
      }
    }
  }
}
</script>
<style type="text/css">
  /* === ラジオボタンは非表示 ================== */
  #CheckNO input[type="radio"]{
    display:none;
  }
  #CheckNO label{
    display    : inline-block;
    border     : 1px solid #ccc;
    box-shadow : 2px 2px #999;
    padding    : 2px 6px;
  }
 /* === 選択されている言語のラベル色を変更 ==== */
  #CheckNO input[type="radio"]:checked + label {
    background : #ffa64d;
  }
  .msg {
    text-align:left;
  }
  .complete {
    color:#0000b0;
    font-size:16pt;
  }
  .rtl {
    direction: rtl;
    unicode-bidi: embed;
  }
</style>
