import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeB from '../views/HomeB.vue'
import HomeC from '../views/HomeC.vue'
import CheckOK from '../views/CheckOK.vue'
import CheckNO from '../views/CheckNO.vue'
import Invalid from '../views/Invalid.vue'
import PostError from '../views/PostError.vue'
import Load from '../views/load.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Load',
    component: Load
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/HomeB',
    name: 'HomeB',
    component: HomeB
  },
  {
    path: '/HomeC',
    name: 'HomeC',
    component: HomeC
  },
  {
    path: '/invalid',
    name: 'Invalid',
    component: Invalid
  },
  {
    path: '/posterror',
    name: 'PostError',
    component: PostError
  },
  {
    path: '/checkok',
    name: 'CheckOK',
    component: CheckOK
  },
  {
    path: '/checkno',
    name: 'CheckNO',
    component: CheckNO
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
