<template>
  <div id="load">
    Loading...
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'loaing-screen',
  components: {
  },
  mounted: function () {
    var userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
      window.location.href = 'https://stnck.suzuki.co.jp/confirm/mc.aspx?Op=' + this.$route.query.tc
    } else {
      this.$router.replace({ path: 'Home', query: { tc: this.$route.query.tc } })
    }
  }
}
</script>
