/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "confirmsttable-dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "confirmstapi",
            "endpoint": "https://api.confirm-st.suzuki.co.jp",
            //"endpoint": "https://dolvz5yx52.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "confirmsystemst-20231009230157-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1u7je0tdd4ccg.cloudfront.net"
};


export default awsmobile;
