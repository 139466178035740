<template>
  <div id="PostError">
    <table align="center">
      <tr>
        <td class="msg">
          <p class="langCng" lang="ja" style="display:none;">
              <font class="noconfirm">処理に失敗しました。</font><br /><br /><br />
              再度、メールに記載されたURLからやり直していただきますようよろしくお願いいたします。<br />
              URLは下記形式で記載されております。文字数等をご確認ください。<br />
          </p>
          <p class="langCng" lang="en" >
            <font class="noconfirm">Error.</font><br /><br /><br />
            Please check the URL written in our e-mail and please try again.<br />
            The URL is written an follows. Please check number of character.
          </p>
          <p class="langCng" lang="cs" style="display:none;">
            <font class="noconfirm">Chyba.</font><br /><br /><br />
            Adresa URL, na kterou jste vstoupili, je neplatná. Zkontrolujte prosím adresu URL uvedenou v našem e-mailu a zkuste to prosím znovu.<br />
            Adresa URL je napsána následovně. Zkontrolujte počet znaků.
          </p>
          <p class="langCng" lang="da" style="display:none;">
            <font class="noconfirm">Fejl.</font><br /><br /><br />
            Den URL, du har tilgået, er ugyldig. Kontroller venligst den URL, der er skrevet i vores e-mail, og prøv igen.<br />
            URL'en er skrevet som følger. Kontroller venligst antallet af tegn.
          </p>
          <p class="langCng" lang="nl" style="display:none;">
            <font class="noconfirm">Feil.</font><br /><br /><br />
            De URL die u hebt geopend is ongeldig. Controleer de URL die in onze e-mail staat en probeer het opnieuw.<br />
            De URL is als volgt geschreven. Controleer het aantal tekens.
          </p>
          <p class="langCng" lang="es" style="display:none;">
            <font class="noconfirm">Error.</font><br /><br /><br />
            La URL a la que accedió no es válida. Por favor, compruebe la URL escrita en nuestro correo electrónico e inténtelo de nuevo.<br />
            La URL se escribe de la siguiente manera. Por favor, compruebe el número de caracteres.
          </p>
          <p class="langCng" lang="fr" style="display:none;">
            <font class="noconfirm">Erreur.</font><br /><br /><br />
            L’URL à laquelle vous avez accédé n’est pas valide. Veuillez vérifier l’URL écrite dans notre e-mail et veuillez réessayer.<br />
            L’URL est écrite comme suit. Veuillez vérifier le nombre de caractères.
          </p>
          <p class="langCng" lang="de" style="display:none;">
            <font class="noconfirm">Fehler.</font><br /><br /><br />
            Die URL, auf die Sie zugegriffen haben, ist ungültig. Bitte überprüfen Sie die in unserer E-Mail angegebene URL und versuchen Sie es erneut.<br />
            Die URL ist wie folgt geschrieben. Bitte überprüfen Sie die Anzahl der Zeichen.
          </p>
          <p class="langCng" lang="el" style="display:none;">
            <font class="noconfirm">Σφάλμα.</font><br /><br /><br />
            Η διεύθυνση URL στην οποία αποκτήσατε πρόσβαση δεν είναι έγκυρη. Ελέγξτε τη διεύθυνση URL που είναι γραμμένη στο e-mail μας και προσπαθήστε ξανά.<br />
            Η διεύθυνση URL είναι γραμμένη ως εξής. Ελέγξτε τον αριθμό των χαρακτήρων.
          </p>
          <p class="langCng" lang="hu" style="display:none;">
            <font class="noconfirm">Hiba.</font><br /><br /><br />
            Az elért URL érvénytelen. Kérjük, ellenőrizze az e-mailünkben írt URL-t, és kérjük, próbálja újra.<br />
            Az URL-cím a következőképpen van írva. Kérjük, ellenőrizze a karakterek számát.
          </p>
          <p class="langCng" lang="it" style="display:none;">
            <font class="noconfirm">Errore.</font><br /><br /><br />
            L'URL a cui si è acceduto non è valido. Controllare l'URL scritto nella nostra e-mail e riprovare.<br />
            L'URL è scritto come segue. Controllare il numero di caratteri.
          </p>
          <p class="langCng" lang="no" style="display:none;">
            <font class="noconfirm">Feil.</font><br /><br /><br />
            URL-adressen du åpnet, er ugyldig. Kontroller URL-adressen som er skrevet i e-posten vår, og prøv på nytt.<br />
            URL-adressen er skrevet nedenfor. Kontroller antall tegn.
          </p>
          <p class="langCng" lang="pl" style="display:none;">
            <font class="noconfirm">Błąd.</font><br /><br /><br />
            Adres URL, do którego uzyskano dostęp, jest nieprawidłowy. Prosimy sprawdzić adres URL podany w naszej wiadomości e-mail i spróbować ponownie.<br />
            Adres URL jest zapisany w następujący sposób. Proszę sprawdzić liczbę znaków.
          </p>
          <p class="langCng" lang="pt" style="display:none;">
            <font class="noconfirm">Erro.</font><br /><br /><br />
            O URL a que acedeu é inválido. Por favor, verifique o URL escrito no nosso e-mail e tente novamente.<br />
            O URL é escrito da seguinte forma. Por favor, verifique o número de caracteres.
          </p>
          <p class="langCng" lang="ro" style="display:none;">
            <font class="noconfirm">Eroare.</font><br /><br /><br />
            URL-ul pe care l-ați accesat nu este valid. Vă rugăm să verificați URL-ul scris în e-mailul nostru și să încercați din nou.<br />
            URL-ul este scris în felul următor. Vă rugăm să verificați numărul de caractere.
          </p>
          <p class="langCng" lang="sk" style="display:none;">
            <font class="noconfirm">Chyba.</font><br /><br /><br />
            URL adresa, ku ktorej ste sa dostali, je neplatná. Skontrolujte URL adresu napísanú v našom e-maile a skúste to znova.<br />
            URL adresa je napísaná nasledovne. Skontrolujte počet znakov.
          </p>
          <p class="langCng" lang="sv" style="display:none;">
            <font class="noconfirm">Fel.</font><br /><br /><br />
            Webbadressen du öppnade är ogiltig. Kontrollera webbadressen som står i vårt e-postmeddelande och försök igen.<br />
            URL:en skrivs på följande sätt. Kontrollera antalet tecken.
          </p>
          <p class="langCng rtl" lang="he" style="display:none;">
            <font class="noconfirm">שְׁגִיאָה.</font><br /><br /><br />
            אנא בדוק את כתובת האתר הכתובה בדוא"ל שלנו ונסה שוב.<br />
            כתובת האתר כתובה כדלקמן. אנא בדוק את מספר התווים.
          </p>
          <img alt="error" src="../assets/error.png">
        </td>
      </tr>
    </table>
    <div id="fotter">
      <p>🄫SUZUKI MOTOR CORPORATION</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'PostError',
  components: {
  },
  methods: {
    langSet: function (argLang) {
      var elm = document.getElementsByClassName('langCng')
      for (var i = 0; i < elm.length; i++) {
        // --- 選択された言語と一致は表示、その他は非表示 -------
        if (elm[i].getAttribute('lang') === argLang) {
          elm[i].style.display = ''
        } else {
          elm[i].style.display = 'none'
        }
      }
    }
  },
  mounted: function () {
    // ブラウザの言語を取得
    var wDef = (window.navigator.browserLanguage || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage).substr(0, 2)
    // wDef = this.$route.query.la
    this.langSet(wDef)
    var btn = document.getElementsByName('langKbn')
    for (var i = 0; i < btn.length; i++) {
      // --- 選択された言語のボタンをチェック状態にする -------
      if (btn[i].getAttribute('id') === 's' + wDef) {
        btn[i].checked = true
      } else {
        btn[i].checked = false
      }
    }
  }
}
</script>
<style type="text/css">
  /* === ラジオボタンは非表示 ================== */
  #PostError input[type="radio"]{
      display:none;
  }
  #PostError label{
    display    : inline-block;
    border     : 1px solid #ccc;
    box-shadow : 2px 2px #999;
    padding    : 2px 6px;
  }
  /* === 選択されている言語のラベル色を変更 ==== */
  #PostError input[type="radio"]:checked + label {
    background : #ffa64d;
  }
  .msg {
    text-align:left;
  }
  .noconfirm {
    color:#ff0000;
    font-size:16pt;
  }
  .rtl {
    direction: rtl;
    unicode-bidi: embed;
  }
</style>
